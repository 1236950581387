import React, { useEffect, useState } from 'react';
import ProjectThumbnail from '../components/ProjectThumbnail';
import Select from 'react-select';
import { TOKEN_KEY, isValidToken } from '../utils';
import LoginComponent from '../components/LoginComponent';
import ResponsiveTable from '../components/ResponsiveTable';
import CloseableCard from '../components/CloseableCard';
import CenterColumn from '../components/CenterColumn';

import "./ProjectsPage.css"
import { SingleValue } from 'react-select/animated';

export default function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [types, setTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [years, setYears] = useState([]);

  const [typeFilter, setTypeFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);

  const [fulltextFilter, setFulltextFilter] = useState("");

  const user_token = localStorage.getItem(TOKEN_KEY);

  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  async function getProjectsData() {
    let filters = "";
    let counter = 0;
    for (let i = 0; i < typeFilter.length; i++) {
      const type = typeFilter[i];
      filters += `filters[$or][${counter++}][services][$in]=${type.value}&`
    }
    for (let i = 0; i < clientFilter.length; i++) {
      const client = clientFilter[i];
      filters += `filters[clientName][$eq]=${client.value}&`
    }
    for (let i = 0; i < yearFilter.length; i++) {
      const year = yearFilter[i];
      filters += `filters[year][$eq]=${year.value}&`
    }
    if (fulltextFilter.length !== 0) {
      filters += `filters[$or][${counter++}][name][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][clientName][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][year][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][description][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][internal][account][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][internal][projectManager][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][internal][budget][$containsi]=${fulltextFilter}&`
      filters += `filters[$or][${counter++}][internal][internalDescription][$containsi]=${fulltextFilter}&`
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/projects?sort[0]=name:asc&${filters}populate[thumbnail][populate]=true&populate[services][populate]=true&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage}`, {
      headers: {
        'Authorization': `Bearer ${user_token}`
      }
    });
    if (!res.ok) return;
    const json = await res.json();
    setProjects(json.data);
    setTotalPages(json.meta.pagination.pageCount);
  }

  useEffect(() => {
    getProjectsData();
  }, [typeFilter, clientFilter, yearFilter, fulltextFilter, pageSize, currentPage]);

  useEffect(() => {
    async function getData() {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/services`, {
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });
      if (!res.ok) return;
      const json = await res.json();
      const types = [];
      json.data.forEach(element => {
        types.push({
          value: element.id,
          label: element.attributes.name
        })
      });
      setTypes(types);
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      let allData = [];
      let page = 1;
      let hasMorePages = true;

      while (hasMorePages) {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/projects?fields[0]=clientName&fields[1]=year&pagination[pageSize]=100&pagination[page]=${page}`, {
          headers: {
        'Authorization': `Bearer ${user_token}`
          }
        });
        if (!res.ok) return;

        const json = await res.json();
        allData = [...allData, ...json.data];
        
        hasMorePages = page < json.meta.pagination.pageCount;
        page++;
      }

      const rawClients = allData.map(x => x.attributes.clientName);
      const clientsFiltered = [...new Set(rawClients)].sort();
      setClients(clientsFiltered.map(x => {
        return {
          value: x,
          label: x
        }
      }));

      const rawYears = allData.map(x => x.attributes.year);
      const yearsFiltered = [...new Set(rawYears)].sort();
      setYears(yearsFiltered.map(x => {
        return {
          value: x,
          label: x
        }
      }));
    }
    getData();
  }, []);

  async function addProject() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/internal-project-datas`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_token}`
        },
        body: JSON.stringify({
          data: {}
        })
      });
      const json = await response.json();

      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_token}`
        },
        body: JSON.stringify({
          data: {
            name: "New project",
            internal: json.data.id
          }
        })
      });

      const json2 = await response2.json();
      window.location.href = `/projects/${json2.data.id}/edit`;
    } catch (error) {
      alert("Error creating project");
      console.error(error);
    }
  }

  async function removeProject(id) {
    const project = projects[projects.findIndex(x => x.id === id)];
    console.log(project);
    const confirmed = window.confirm(`Are you sure you want to remove project: ${project.attributes.name}? This cannot be undone!`);
    if (!confirmed)
      return;

    try {
      const resposnseFullObject = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}?populate[thumbnail][populate]=true&populate[services][populate]=true&populate[outputs][populate][0]=file&populate[outputs][populate][1]=images&populate[internal][populate]=true`, {
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });
      if (!resposnseFullObject.ok) {
        alert("Error verifying project");
        return;
      }
      const json = await resposnseFullObject.json();
      console.log(json);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${json.data.id}`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });

      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/api/internal-project-datas/${json.data.attributes.internal.data.id}`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });

      alert(response.ok && response2.ok ? "The project has been deleted" : "Error");
      getProjectsData();
    } catch (error) {
      alert("Error deleting project");
      console.error(error);
    }
  }

  async function clearPagination() {
    setCurrentPage(1);
    setPageSize(10);
  }

  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#232323",

      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: "white",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ':hover': {
        color: 'red',
      },
    }),
  };


  return (
    <>
      {!isValidToken(user_token) && <LoginComponent />}
      {isValidToken(user_token) && <div>
        <CenterColumn>
          <p></p>
          <div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap', gap: "10px" }}>
            <img width={250} src='/CreativeDreamers_white.svg' />
            <Select
              options={types}
              isLoading={types == null}
              isMulti
              onChange={setTypeFilter}
              placeholder="Typ projektu..."
              className='select'
              classNamePrefix='select'
              styles={colorStyles}
            />
            <Select
              options={clients}
              isLoading={clients == null}
              isMulti
              onChange={setClientFilter}
              placeholder="Klient..."
              className='select'
              classNamePrefix='select'
              styles={colorStyles}
            />
            <Select
              options={years}
              isLoading={years == null}
              isMulti
              onChange={setYearFilter}
              placeholder="Rok realizace..."
              className='select'
              classNamePrefix='select'
              styles={colorStyles}
            />
            <input className='fulltext-input' type='text' placeholder='Fulltext 🔍' value={fulltextFilter} onChange={e => setFulltextFilter(e.target.value)} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", alignItems: "center" }}>
            <span>Page size:</span>
            <Select
              options={pageSizeOptions}
              value={pageSizeOptions.find(option => option.value === pageSize)}
              onChange={(option) => setPageSize(option.value)}
              className='select'
              classNamePrefix='select'
              styles={colorStyles}
            />
            <span>&nbsp;&nbsp;&nbsp;&nbsp;Page:</span>
            <input
              type="number"
              min="1"
              max={totalPages}
              value={currentPage}
              onChange={(e) => setCurrentPage(parseInt(e.target.value))}
              style={{ width: "70px" }}
              placeholder="Page"
            />
            <span>of {totalPages}&nbsp;&nbsp;&nbsp;</span>

            <button onClick={clearPagination}>Reset pagination</button>
          </div>
          {/* <h1>Projects</h1> */}
          <button onClick={addProject}>Add project ➕</button>
          <ResponsiveTable>
            {projects?.map(project => <CloseableCard onClose={() => removeProject(project.id)}>
              <ProjectThumbnail project={project} />
            </CloseableCard>)}
          </ResponsiveTable>
        </CenterColumn>
      </div>}
    </>
  );
};
